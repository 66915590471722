import {
  Alert,
  Box,
  Button,
  Fade,
  Grow,
  IconButton,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { BotAvatar } from "../BotAvatar";
import React, {
  FormEventHandler,
  UIEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../loader.css";
import { Flipped, Flipper } from "react-flip-toolkit";
import { NotFoundPage } from "../NotFound";
import { BotMessage, Message, TenantConfigResponse, VoteState } from "./types";
import { FetchError, getJSON, throwIfNotOk } from "../fetch";
import { SuggestedPrompt } from "./SuggestedPrompt";
import { UserMessageComponent } from "./UserMessageComponent";
import { BotMessageComponent } from "./BotMessageComponent";
import { MainLayout } from "../MainLayout";
import { createCustomTheme, defaultTheme } from "../../theme";
import { ThemeProvider } from "@mui/material/styles";
import { QueryEngineResponse, UsedRef } from "./queryEngineTypes";
import GoogleLogin from "../GoogleAuth";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { ArrowDownward } from "@mui/icons-material";

export function BotChat() {
  const { tenantId, botId } = useParams();
  const [userQuery, setUserQuery] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authCheckFinished, setAuthCheckFinished] = useState(false);
  const [requestIsPending, setRequestIsPending] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [conversation, setConversation] = useState<Message[]>([]);
  const [voteStates, setVoteStates] = useState<VoteState[]>([]);
  const [sessionId, setSessionId] = useState<string>(() => {
    return crypto.randomUUID();
  });
  const sessionIdRef = useRef(sessionId);

  function resetChat() {
    setSessionId((sessionIdRef.current = crypto.randomUUID()));
    setVoteStates([]);
    setConversation([]);
    setRequestError(false);
    setRequestIsPending(false);
  }

  const logout = useCallback(() => {
    return fetch(`${process.env.API_BASE_URL}/logout`, {
      method: "GET",
      credentials: "include",
    }).then(() => {
      setIsLoggedIn(false);
    });
  }, []);

  const tenantConfig = useSWR<TenantConfigResponse>(
    `/api/v1/external-qa/t/${tenantId}/b/${botId}`,
    getJSON,
    {
      onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
        // Don't retry 404s
        if (error instanceof FetchError && error.response.status === 404)
          return;
        if (retryCount >= 10) return;
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
    }
  );

  const theme = useMemo(() => {
    function getHexIfValid(maybeHex?: string) {
      if (maybeHex && /^#([A-Fa-f0-9]{6})$/.test(maybeHex)) {
        return maybeHex;
      }
    }

    let p = getHexIfValid(tenantConfig.data?.bot?.branding?.primaryColor);
    if (p) {
      let t = getHexIfValid(tenantConfig.data?.bot?.branding?.tertiaryColor);
      return createCustomTheme({
        primary: p,
        tertiary: t || p,
      });
    }
    return defaultTheme;
  }, [
    tenantConfig.data?.bot?.branding?.primaryColor,
    tenantConfig.data?.bot?.branding?.tertiaryColor,
  ]);

  const userDidScroll = useRef(false);
  useEffect(() => {
    const userScrolled = () => {
      userDidScroll.current = true;
    };
    window.addEventListener("wheel", userScrolled, { passive: true });
    window.addEventListener("touchmove", userScrolled, { passive: true });

    const handleKeydown = (e: KeyboardEvent) => {
      const keysThatIndicateIntentToScroll = [
        "ArrowDown",
        "ArrowUp",
        "PageUp",
        "PageDown",
        "Home",
        "End",
      ];
      if (keysThatIndicateIntentToScroll.includes(e.key)) {
        userScrolled();
      }
    };
    window.addEventListener("keydown", handleKeydown, { passive: true });
    return () => {
      window.removeEventListener("wheel", userScrolled);
      window.removeEventListener("touchmove", userScrolled);
      window.removeEventListener("keydown", handleKeydown);
    };
  }, []);
  useEffect(() => {
    userDidScroll.current = false;
  }, [conversation.length]);
  useEffect(() => {
    if (userDidScroll.current) return;
    const target = document.getElementById("lastMessage");
    if (!target) return;
    const container = document.getElementById("scrollContainer");
    if (!container) return;
    container.scrollTop = target.offsetTop;
  }, [conversation]);

  // Prevent loading this in every occasion. However, putting it on the google auth component, while cleaner
  // leads to additional latency waiting for login button
  useEffect(() => {
    if (!tenantConfig.data || !tenantConfig.data.bot.requires_authentication) {
      return;
    }
    const scripts = document.getElementsByTagName("script");
    const url = "https://accounts.google.com/gsi/client";
    for (var i = scripts.length; i--; ) {
      if (scripts[i].src == url) return;
    }

    const tag = document.createElement("script");
    tag.src = "https://accounts.google.com/gsi/client";
    document.getElementsByTagName("head")[0].appendChild(tag);
  }, [tenantConfig?.data?.bot?.requires_authentication]);

  const sendVote = async (requestId: string, voteState?: VoteState) => {
    const path = "/api/v1/external-qa/vote";
    return await fetch(process.env.API_BASE_URL + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tenant_id: tenantId,
        session_id: sessionId,
        request_id: requestId,
        vote_state: voteState,
      }),
    });
  };

  const onVoteClicked = async (
    index: number,
    message: BotMessage,
    voteState?: VoteState
  ) => {
    if (!message.response) return;
    setVoteStates((p) => {
      const next = [...p];
      if (voteState) {
        next[index] = voteState;
      } else {
        delete next[index];
      }
      return next;
    });
    await sendVote(message.response.request_id, voteState);
  };

  useEffect(() => {
    if (tenantConfig.data?.bot?.requires_authentication) {
      fetch(process.env.API_BASE_URL + "/current_user", {
        method: "GET",
        headers: {
          credentials: "include",
          "Content-Type": "application/json",
        },
      })
        .then((r) => {
          if (r.status === 200) {
            setIsLoggedIn(true);
          }
        })
        .finally(() => {
          setAuthCheckFinished(true);
        });
    }
  }, [tenantConfig.data?.bot?.requires_authentication]);

  if (
    tenantConfig.isLoading ||
    (tenantConfig.data?.bot?.requires_authentication && !authCheckFinished)
  ) {
    return <></>;
  }

  if (!tenantId || !tenantConfig.data || tenantConfig.error) {
    return <NotFoundPage />;
  }

  const { bot } = tenantConfig.data;

  const submit = async (query: string) => {
    if (tenantConfig.data?.flags?.use_streaming) {
      submitStream(query);
      return;
    }

    if (!query) return;
    setUserQuery("");
    setConversation((prev) => [
      ...prev,
      {
        role: "user",
        query,
      },
    ]);
    setRequestIsPending(true);
    setRequestError(false);

    setConversation((prev) => [
      ...prev,
      {
        role: "bot",
        response: null,
        isFinished: false,
      },
    ]);

    try {
      const path = "/api/v1/external-qa/query";
      const response = await fetch(process.env.API_BASE_URL + path, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          query,
          tenant_id: tenantId,
          bot_id: bot.bot_id,
          session_id: sessionId,
        }),
      });
      if (response.status === 401) {
        setIsLoggedIn(false);
      }
      const responseJson = await throwIfNotOk(response);
      if (sessionIdRef.current !== sessionId) return;
      setConversation((prev) => {
        const message: BotMessage = {
          role: "bot",
          response: responseJson,
          isFinished: true,
        };

        let n = prev.slice(0, prev.length - 1);
        n.push(message);
        return n;
      });
    } catch (e) {
      setRequestError(true);
      window.analytics?.track("Query Failed", {
        tenant_id: tenantId,
        session_id: sessionId,
      });
      return;
    } finally {
      setRequestIsPending(false);
    }
  };

  const submitStream = async (query: string) => {
    if (!query) return;
    setUserQuery("");
    setConversation((prev) => [
      ...prev,
      {
        role: "user",
        query,
      },
    ]);
    setRequestIsPending(true);
    setRequestError(false);

    try {
      const path = "/api/v1/external-qa/query";
      await fetchEventSource(process.env.API_BASE_URL + path, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        openWhenHidden: true,
        body: JSON.stringify({
          query,
          tenant_id: tenantId,
          bot_id: bot.bot_id,
          session_id: sessionId,
          stream: true,
        }),
        async onopen(response) {
          // console.debug("onopen", response);
          setConversation((prev) => [
            ...prev,
            {
              role: "bot",
              response: null,
              isFinished: false,
            },
          ]);
        },
        onmessage(message) {
          // console.debug("onmessage", message);

          if (message.data === "") return;

          const parsed: QueryEngineResponse = JSON.parse(message.data);
          setConversation((prev) => {
            const message: BotMessage = {
              role: "bot",
              response: parsed,
              isFinished: false,
            };

            let n = prev.slice(0, prev.length - 1);
            n.push(message);
            return n;
          });
        },
        onclose() {
          // console.debug("onclose");
          setConversation((prev) => {
            let message = {
              ...(prev[prev.length - 1] as BotMessage),
              isFinished: true,
            };
            let n = prev.slice(0, prev.length - 1);
            n.push(message);
            return n;
          });
        },
        onerror(err) {
          // console.debug("onerror", err);

          // Mark message as finished
          setConversation((prev) => {
            let message = {
              ...(prev[prev.length - 1] as BotMessage),
              isFinished: true,
            };
            let n = prev.slice(0, prev.length - 1);
            n.push(message);
            return n;
          });

          // throw to prevent automatic retry
          throw err;
        },
      });
    } catch (e) {
      console.error(e);
      setRequestError(true);
      window.analytics?.track("Query Failed", {
        tenant_id: tenantId,
        session_id: sessionId,
      });
      return;
    } finally {
      setRequestIsPending(false);
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    if (requestIsPending) return;
    window.analytics?.track("Query Submitted", {
      tenant_id: tenantId,
      session_id: sessionId,
    });
    submit(userQuery);
  };

  const handleSuggestedPromptClicked = (prompt: string) => {
    window.analytics?.track("Suggested Query Submitted", {
      tenant_id: tenantId,
      session_id: sessionId,
    });
    submit(prompt);
  };

  const trackLinkClick = async (
    message: BotMessage,
    reference: UsedRef | null,
    link?: string
  ) => {
    if (!message.response) return Promise.reject();
    const path = "/api/v1/external-qa/track";
    return await fetch(process.env.API_BASE_URL + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tenant_id: tenantId,
        session_id: sessionId,
        request_id: message.response.request_id,
        bot_id: botId,
        link: link || null,
        reference: reference
          ? {
              kb_name: reference.kb_name,
              link: reference.link,
              title: reference.title,
              ts: reference.ts,
            }
          : null,
      }),
    });
  };

  const renderForm = () => (
    <Flipped flipId={"chatBox"}>
      <Box
        component={"form"}
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          borderRadius: "27px",
          border: `2px solid transparent`,
          background: (theme) =>
            `linear-gradient(white, white) padding-box, linear-gradient(to bottom, ${theme.customColors.primary}, ${theme.customColors.tertiary}) border-box`,
          width: "100%",
          boxShadow: (theme) => theme.shadows[0],
          transition: (theme) => theme.transitions.create("box-shadow"),
          "&:focus-within": {
            boxShadow: (theme) => theme.shadows[4],
          },
        }}
      >
        <Box
          component={"input"}
          name={"userInput"}
          placeholder={bot.external_qa_placeholder || "Ask anything!"}
          sx={{
            cursor: "pointer",
            width: "100%",
            padding: "8px 20px",
            border: "none",
            background: "transparent",
            outline: "none",
            fontSize: (theme) => theme.typography.body1,
          }}
          value={userQuery}
          onChange={(e) => setUserQuery(e.currentTarget.value)}
        />
        <IconButton
          type={"submit"}
          color={"primary"}
          size={"small"}
          sx={{ p: 1.5, mr: 1 }}
          disabled={requestIsPending}
        >
          <SvgIcon inheritViewBox={true} fill={"currentColor"}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M27.9838 14.25L6.98376 2.26376C6.62978 2.06519 6.22376 1.97902 5.81964 2.01669C5.41552 2.05437 5.03242 2.21411 4.72124 2.47469C4.41007 2.73527 4.18553 3.08436 4.07747 3.47558C3.96942 3.86681 3.98294 4.28165 4.11626 4.66501L7.99126 15.9738C7.99077 15.9779 7.99077 15.9821 7.99126 15.9863C7.99056 15.9904 7.99056 15.9946 7.99126 15.9988L4.11626 27.3325C4.0095 27.6341 3.97662 27.9568 4.02041 28.2737C4.06419 28.5906 4.18335 28.8924 4.36788 29.1537C4.55242 29.415 4.79695 29.6283 5.08094 29.7755C5.36494 29.9228 5.68011 29.9998 6.00001 30C6.3471 29.9991 6.68809 29.9087 6.99001 29.7375L27.9788 17.7313C28.2884 17.5578 28.5462 17.3051 28.7259 16.9991C28.9056 16.6931 29.0007 16.3448 29.0013 15.99C29.002 15.6351 28.9082 15.2864 28.7296 14.9798C28.551 14.6732 28.294 14.4195 27.985 14.245L27.9838 14.25ZM6.00001 28V27.9888L9.76751 17H17C17.2652 17 17.5196 16.8947 17.7071 16.7071C17.8947 16.5196 18 16.2652 18 16C18 15.7348 17.8947 15.4804 17.7071 15.2929C17.5196 15.1054 17.2652 15 17 15H9.77751L6.00751 4.01501L6.00001 4.00001L27 15.9788L6.00001 28Z" />
            </svg>
          </SvgIcon>
        </IconButton>
      </Box>
    </Flipped>
  );

  let content: React.JSX.Element;
  if (conversation.length === 0) {
    let usablePrompts = bot.external_qa_prompts.filter((p) => !!p.prompt);
    let suggestedPrompts = !!usablePrompts.length ? (
      <>
        <Box>
          <Typography variant={"body2"} color={""} sx={{ textAlign: "center" }}>
            Try these prompts, or converse with {bot.name} below
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          {bot.external_qa_prompts.map(({ prompt }) => (
            <SuggestedPrompt
              key={prompt}
              prompt={prompt}
              onClick={() => handleSuggestedPromptClicked(prompt)}
            />
          ))}
        </Box>
      </>
    ) : (
      <Box>
        <Typography variant={"body2"} color={""} sx={{ textAlign: "center" }}>
          Converse with {bot.name} below
        </Typography>
      </Box>
    );

    content = (
      <Layout
        mainContent={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              height: "100%",
              maxWidth: 1024,
              mx: "auto",
              px: {
                xs: 1,
                sm: 2,
                md: 3,
              },
              "& > *": {
                marginLeft: "auto",
                marginRight: "auto",
              },
            }}
          >
            <Box sx={{ marginTop: "auto" }} />
            <Box>
              <Typography variant={"h1"} sx={{ textAlign: "center" }}>
                {bot.external_qa_headline || `Chat with ${bot.name}`}
              </Typography>
            </Box>
            <BotAvatar bot={bot} />
            {suggestedPrompts}
            <Box
              sx={{
                pt: 1,
                pb: 2,
                backgroundColor: "white",
                width: "100%",
                marginBottom: "auto",
                position: "sticky",
                bottom: 0,
              }}
            >
              {renderForm()}
            </Box>
          </Box>
        }
      />
    );
  } else {
    const hasDisclaimer = !!bot.external_qa_disclaimer;
    content = (
      <Layout
        mainContent={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              height: "100%",
              maxWidth: 1024,
              mx: "auto",
              position: "relative",
              px: {
                xs: 1,
                sm: 2,
                md: 3,
              },
            }}
          >
            {conversation.flatMap((message, i) => {
              const isLast = i === conversation.length - 1;
              return message.role === "user" ? (
                <UserMessageComponent
                  key={i}
                  message={message}
                  id={isLast ? "lastMessage" : undefined}
                />
              ) : (
                <BotMessageComponent
                  key={i}
                  id={isLast ? "lastMessage" : undefined}
                  message={message}
                  bot={bot}
                  vote={voteStates[i]}
                  trackLinkClick={trackLinkClick}
                  onVote={(voteState) => {
                    onVoteClicked(i, message, voteState);
                  }}
                />
              );
            })}
            {requestError && <Alert color={"error"}>An error occurred</Alert>}
          </Box>
        }
        bottomSlot={
          <Box
            sx={{
              pb: hasDisclaimer ? 1 : 4,
              backgroundColor: "white",
              maxWidth: 1024,
              width: "100%",
              mx: "auto",
              px: {
                xs: 1,
                sm: 2,
                md: 3,
              },
            }}
          >
            {renderForm()}
            {hasDisclaimer && (
              <Fade timeout={1000} in>
                <Typography
                  sx={{ textAlign: "center", mt: 1, fontSize: "12px" }}
                >
                  {bot.external_qa_disclaimer}
                </Typography>
              </Fade>
            )}
          </Box>
        }
        action={
          <>
            <Button color={"secondary"} onClick={resetChat}>
              Reset Chat
            </Button>
            {isLoggedIn && (
              <Button color={"secondary"} onClick={logout}>
                Log Out
              </Button>
            )}
          </>
        }
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      {bot.requires_authentication && !isLoggedIn ? (
        <GoogleLogin bot={bot} onLogin={() => setIsLoggedIn(true)} />
      ) : (
        <Flipper
          className={"flipper"}
          flipKey={"conv-" + conversation.length}
          debug={false}
        >
          {content}
        </Flipper>
      )}
    </ThemeProvider>
  );
}

interface LayoutProps {
  mainContent: React.ReactNode;
  bottomSlot?: React.ReactNode;
  action?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ mainContent, bottomSlot, action }) => {
  const [distanceFromBottom, setDistanceFromBottom] = useState(0);
  const showScrollIndicator = Math.floor(distanceFromBottom) > 0;

  const onScroll: UIEventHandler = (e) => {
    let c = e.currentTarget;
    let rect = e.currentTarget.getBoundingClientRect();
    setDistanceFromBottom(c.scrollHeight - (c.scrollTop + rect.height));
  };

  return (
    <MainLayout action={action}>
      <Box sx={{ height: `100%`, display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            overflowY: "hidden",
            flex: "1 1 0",
            position: "relative",
            "::after": {
              content: '" "',
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: 35,
              pointerEvents: "none",
              background: (t) =>
                `linear-gradient(transparent, ${t.palette.background.default})`,
              opacity: showScrollIndicator ? 1 : 0,
            },
          }}
        >
          <Grow
            in={showScrollIndicator}
            {...(!showScrollIndicator ? {} : { timeout: 1000 })}
          >
            <IconButton
              size={"small"}
              onClick={() => {
                const c = document.getElementById("scrollContainer");
                if (!c) return;
                c.scrollTop = c.scrollHeight;
              }}
              sx={{
                border: (t) => `1px solid ${t.customColors.borderColor}`,
                height: 36,
                width: 36,
                zIndex: 2,
                position: "absolute",
                bottom: 4,
                left: 0,
                right: 0,
                mx: "auto",

                // Use important to override inline style
                backgroundColor: (t) =>
                  `${t.palette.background.default} !important`,
              }}
            >
              <ArrowDownward />
            </IconButton>
          </Grow>
          <Box
            id={"scrollContainer"}
            onScroll={onScroll}
            sx={{
              height: "100%",
              overflowY: "auto",
              scrollBehavior: "smooth",
              overscrollBehavior: "contain",
            }}
          >
            {mainContent}
          </Box>
        </Box>
        {bottomSlot}
      </Box>
    </MainLayout>
  );
};
