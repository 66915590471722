import React, { PropsWithChildren } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import { useSearchParams } from "react-router-dom";

type MainLayoutProps = PropsWithChildren<{
  action?: React.ReactNode;
}>;

export function MainLayout({ children, action }: MainLayoutProps) {
  const widthIsSmall = useMediaQuery(`(width <= 500px`);
  const heightIsSmall = useMediaQuery(`(height <= 500px`);
  const useSmallHeader = widthIsSmall || heightIsSmall;

  const [searchParams] = useSearchParams();
  const showLogo = !searchParams.has("embed");
  return (
    <Box sx={{ height: `100svh`, display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          p: useSmallHeader ? 1 : 2,
        }}
      >
        <Box
          component={"img"}
          src={`/svg_icons/logo-querypal.svg`}
          sx={{
            height: useSmallHeader ? 25 : 40,
            display: showLogo ? "block" : "none",
          }}
        />

        <Box sx={{ ml: "auto" }}>{action}</Box>
      </Box>
      <Box sx={{ flex: "auto" }}>{children}</Box>
    </Box>
  );
}
