export class FetchError extends Error {
  response: Response;

  constructor({ response }: { response: Response }) {
    super();
    this.response = response;
  }
}

export async function getJSON(path: string) {
  const response = await fetch(process.env.API_BASE_URL + path);
  return throwIfNotOk(response);
}

export async function throwIfNotOk(response: Response) {
  if (response.ok) {
    return await response.json();
  } else {
    throw new FetchError({ response });
  }
}
