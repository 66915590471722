import React, { useEffect, useRef, useState } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { Link, Theme } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Bot } from "./botChat/types";

interface GoogleLoginProps {
  bot: Bot;
  onLogin: () => void;
}

export const GoogleLogin: React.FC<GoogleLoginProps> = ({ bot, onLogin }) => {
  const [isSignup, setIsSignup] = useState(false);
  const googleInitialized = useRef(false);

  const renderGoogleButton = () => {
    const rect = document
      .getElementById("googleAuthDiv")
      ?.parentElement?.getBoundingClientRect();
    // @ts-ignore
    google.accounts.id.renderButton(
      document.getElementById("googleAuthDiv"),
      { width: "360px", theme: "dark", longtitle: true } // customization attributes
    );
  };

  const setUpGoogle = (ac: AbortController, tries = 0) => {
    console.log("setupGoogle, current = ", googleInitialized.current);
    if (googleInitialized.current) {
      return;
    }
    // @ts-ignore
    try {
      {
        // @ts-ignore
        google.accounts.id.initialize({
          client_id:
            "690215227185-h87r4gdco90grn7eargv2ll191c7cc7j.apps.googleusercontent.com",
          hd: "*",
          error_callback: (err: any) => {
            console.log("Google sign-in error", err);
          },
          callback: (r: any) => {
            fetch(
              `${process.env.API_BASE_URL}/web_auth/google_workspace/callback`,
              {
                method: "POST",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify({
                  client_id: r.client_id,
                  credential: r.credential,
                }),
                credentials: "include",
              }
            ).then(async (response) => {
              if (!response.ok) {
                return;
              }
              if (response.ok) {
                onLogin();
              }
            });
          },
        });
        renderGoogleButton();
        googleInitialized.current = true;
      }
    } catch (e) {
      setTimeout(() => {
        if (ac.signal.aborted) return;
        if (tries > 60) {
          console.error("failed to load google auth, giving up", e);
          return;
        }
        setUpGoogle(ac, tries + 1);
      }, 1000);
      return;
    }
  };

  useEffect(() => {
    let ac = new AbortController();
    googleInitialized.current = false;
    setUpGoogle(ac);
    return () => ac.abort();
  }, [window.location.pathname]);

  const theme = useTheme() as Theme;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        background: `linear-gradient(180deg, #EAEFF5 54.37%, ${theme.customColors.primary} )`,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
        <Typography variant={"h4"}>
          {/*<Icon icon={"logo-querypal"} style={{ height: `48px` }} />*/}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          mt: "15vh",
        }}
      >
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: 600,
            fontFamily: "Poppins",
            mb: 1,
          }}
        >
          {bot.external_qa_headline || `Chat with ${bot.name}`}
        </Typography>
        <Typography
          sx={{
            fontSize: "24px",
            mb: 2,
          }}
        >
          Log in to continue
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "min(433px, 100vw)",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "360px",
            }}
          >
            <div id="googleAuthDiv" />
          </div>
          <Typography>
            {isSignup ? "Already have an account?" : "Don't have an account?"}{" "}
            <Link
              underline={"none"}
              sx={{ cursor: "pointer" }}
              onClick={() => setIsSignup((cur) => !cur)}
            >
              {isSignup ? "Log In" : "Sign Up"}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GoogleLogin;
