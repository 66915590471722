import {
  Alert,
  Box,
  Button,
  Collapse,
  Fade,
  IconButton,
  styled,
  SvgIcon,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { format } from "date-fns";
import { Bot, BotMessage, VoteState } from "./types";
import { BotAvatar } from "../BotAvatar";
import Icon from "../Icon";
import { getIntegrationDisplayName } from "../integrationKind";
import { Close } from "@mui/icons-material";
import { compiler } from "markdown-to-jsx";
import {
  GPTAnswer,
  KbCombinedResult,
  KbReferenceOnlyResult,
  KbToolResult,
  SpecialCaseAnswer,
  UsedRef,
} from "./queryEngineTypes";

export function BotMessageComponent({
  message,
  bot,
  vote,
  onVote,
  trackLinkClick,
  id,
}: {
  id?: string;
  message: BotMessage;
  vote?: VoteState;
  bot: Bot;
  onVote: (voteState?: VoteState) => void;
  trackLinkClick: (
    message: BotMessage,
    reference: UsedRef | null,
    link?: string
  ) => void;
}) {
  const [showDownvoteJustifier, setShowDownvoteJustifier] = useState(false);

  const contentRef = useRef<HTMLDivElement>();

  const [copiedTooltipShowing, setCopiedTooltipShowing] = useState(false);
  useEffect(() => {
    if (!copiedTooltipShowing) return;
    const id = setTimeout(() => {
      setCopiedTooltipShowing(false);
    }, 5000);
    return () => clearTimeout(id);
  }, [copiedTooltipShowing]);

  const onAnchorClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      if (!(event.target instanceof HTMLAnchorElement)) return;
      if (!message.response) return;

      let href = event.target.href;

      // Hunt for the ref...
      let ref: UsedRef | null = null;
      for (let m of message.response.all_messages) {
        switch (m.message_type) {
          case "KbCombinedResult":
          case "KbReferenceOnlyResult":
          case "KbToolResult":
            let f = m.result.used_refs.find((ur) => ur.link === href);
            if (f) {
              ref = f;
            }
            break;
          case "gpt_answer":
            break;
          case "special_case_answer":
            break;
        }
      }

      if (ref) {
        trackLinkClick(message, ref, ref.link);
      } else {
        trackLinkClick(message, null, href);
      }
    },
    [message]
  );

  const showLoader =
    (!message.response ||
      message.response.all_messages.length === 0 ||
      (message.response.all_messages.length === 1 &&
        message.response.all_messages[0].message_type ===
          "StartingKBSearch")) &&
    !message.isFinished;

  const showError = !showLoader && message.response?.any_search_timed_out;

  return (
    <Box
      id={id}
      sx={{
        display: "flex",
        gap: 2,
        p: 2,
      }}
    >
      <BotAvatar bot={bot} size={40} />
      <Box sx={{ mt: 1 }}>
        {showLoader && (
          <Box
            className={"dot-pulse"}
            sx={{
              "--pulse-color": (theme) => theme.customColors.primary,
              ml: 2.5,
              mt: 1,
            }}
          />
        )}
        <Box ref={contentRef}>
          {(function () {
            let messages = message.response?.all_messages;
            if (messages && messages.length) {
              let jsx = [];
              let gpt = messages.find(
                (m): m is GPTAnswer | SpecialCaseAnswer =>
                  m.message_type === "gpt_answer" ||
                  m.message_type === "special_case_answer"
              );
              let kbm = messages.filter(
                (m): m is KbToolResult => m.message_type === "KbToolResult"
              );
              let kbc = messages.filter(
                (m): m is KbCombinedResult =>
                  m.message_type === "KbCombinedResult"
              );
              let kbr = messages.filter(
                (m): m is KbReferenceOnlyResult =>
                  m.message_type === "KbReferenceOnlyResult"
              );

              if (kbr.length) {
                kbr.forEach((m, i) => {
                  jsx.push(
                    <Box key={i} sx={{ mb: 0.5 }}>
                      <Ol>
                        {m.result.used_refs.map((ur, i) => {
                          return (
                            <Li key={i}>
                              <Box
                                sx={{
                                  my: 1,
                                }}
                              >
                                <Icon
                                  icon={`integ-${ur.kb_name}`}
                                  data-copy={false}
                                  sx={{
                                    width: 24,
                                    height: 24,
                                    verticalAlign: "sub",
                                    mx: 0.5,
                                  }}
                                />
                                <Typography
                                  fontSize={14}
                                  component={"a"}
                                  href={ur.link}
                                  target={"_blank"}
                                  onClick={() => trackLinkClick(message, ur)}
                                >
                                  {ur.title}
                                </Typography>
                                <Typography
                                  fontSize={14}
                                  component={"span"}
                                  sx={{ ml: 0.5 }}
                                >
                                  {tryFormat(ur.ts)}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  pl: 2,
                                  borderLeft: (t: Theme) =>
                                    `4px solid ${t.palette.divider}`,
                                  ml: "-14px",
                                }}
                              >
                                {ur.snippet}
                              </Box>
                            </Li>
                          );
                        })}
                      </Ol>
                    </Box>
                  );
                });
              } else if (kbc.length) {
                kbc.forEach((m, i) => {
                  let sources = !!m.result.used_refs?.length && (
                    <>
                      <Fade in={message.isFinished} mountOnEnter={true}>
                        <Typography fontWeight={"bold"} sx={{ mt: 2, mb: 1 }}>
                          Sources:
                        </Typography>
                      </Fade>
                      <Fade
                        in={message.isFinished}
                        timeout={1000}
                        mountOnEnter={true}
                      >
                        <Ol>
                          {m.result.used_refs.map((ur, i) => {
                            return (
                              <Li key={i}>
                                <Icon
                                  data-copy={false}
                                  icon={`integ-${ur.kb_name}`}
                                  sx={{
                                    width: 24,
                                    height: 24,
                                    verticalAlign: "sub",
                                    mx: 0.5,
                                  }}
                                />
                                <Typography
                                  color={"primary"}
                                  fontSize={14}
                                  component={"a"}
                                  href={ur.link}
                                  target={"_blank"}
                                  onClick={() => trackLinkClick(message, ur)}
                                >
                                  {ur.title}
                                </Typography>
                                <Typography
                                  fontSize={14}
                                  component={"span"}
                                  sx={{ mx: 0.5 }}
                                >
                                  &nbsp;{tryFormat(ur.ts)}
                                </Typography>
                              </Li>
                            );
                          })}
                        </Ol>
                      </Fade>
                    </>
                  );

                  jsx.push(
                    <Box key={i} sx={{ mb: 0.5 }}>
                      <MD onAnchorClick={onAnchorClick}>{m.result.answer}</MD>
                      {sources}
                    </Box>
                  );
                });
              } else if (kbm.length) {
                kbm.forEach((m, i) => {
                  jsx.push(
                    <Box key={i} sx={{ mb: 0.5 }}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <Icon
                          data-copy={false}
                          icon={`integ-${m.result.kb_name}`}
                          sx={{ width: 20, height: 20 }}
                        />
                        <Typography fontSize={14}>
                          {getIntegrationDisplayName(m.result.kb_name)}
                        </Typography>
                      </Box>

                      <MD onAnchorClick={onAnchorClick}>{m.result.answer}</MD>
                      <Ol>
                        {m.result.used_refs.map((ur, i) => {
                          return (
                            <Li key={i}>
                              <Typography
                                fontSize={14}
                                component={"a"}
                                href={ur.link}
                                target={"_blank"}
                                onClick={() =>
                                  trackLinkClick(message, {
                                    ...ur,
                                    kb_name: m.result.kb_name,
                                  })
                                }
                              >
                                {ur.title}
                              </Typography>
                              <Typography
                                fontSize={14}
                                component={"span"}
                                sx={{ ml: 0.5 }}
                              >
                                &nbsp;{tryFormat(ur.ts)}
                              </Typography>
                            </Li>
                          );
                        })}
                      </Ol>
                    </Box>
                  );
                });
              } else if (gpt) {
                jsx.push(
                  <Box key={"gpt"}>
                    <MD onAnchorClick={onAnchorClick}>{gpt.answer}</MD>
                  </Box>
                );
              }

              return jsx;
            }
            return <></>;
          })()}
        </Box>
        {showError && <Alert color={"error"}>An error occurred.</Alert>}
        <Fade in={message.isFinished} timeout={1000} mountOnEnter={true}>
          <Box sx={{ mt: 1 }}>
            <Tooltip
              title={"Copied to clipboard!"}
              open={copiedTooltipShowing}
              arrow
            >
              <IconButton
                onClick={async () => {
                  if (!contentRef.current) return;
                  const content = contentRef.current.cloneNode(
                    true
                  ) as HTMLElement;
                  const nodesToRemove = content.querySelectorAll(
                    '[data-copy="false"]'
                  );
                  nodesToRemove.forEach((node) => node.remove());

                  const data = [
                    new ClipboardItem({
                      "text/plain": new Blob([content.innerText], {
                        type: "text/plain",
                      }),
                      "text/html": new Blob([content.innerHTML], {
                        type: "text/html",
                      }),
                    }),
                  ];

                  try {
                    await navigator.clipboard.write(data);
                    setCopiedTooltipShowing(true);
                  } catch (err) {
                    console.error("Failed to copy: ", err);
                  }
                }}
              >
                <SvgIcon
                  sx={{
                    height: 24,
                    width: 20,
                  }}
                  inheritViewBox={true}
                  fill={"currentColor"}
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M25 3.99994H20.4675C19.9056 3.37085 19.2172 2.86751 18.4473 2.52289C17.6775 2.17826 16.8435 2.00012 16 2.00012C15.1565 2.00012 14.3225 2.17826 13.5527 2.52289C12.7828 2.86751 12.0944 3.37085 11.5325 3.99994H7C6.46957 3.99994 5.96086 4.21065 5.58579 4.58573C5.21071 4.9608 5 5.46951 5 5.99994V26.9999C5 27.5304 5.21071 28.0391 5.58579 28.4142C5.96086 28.7892 6.46957 28.9999 7 28.9999H25C25.5304 28.9999 26.0391 28.7892 26.4142 28.4142C26.7893 28.0391 27 27.5304 27 26.9999V5.99994C27 5.46951 26.7893 4.9608 26.4142 4.58573C26.0391 4.21065 25.5304 3.99994 25 3.99994ZM16 3.99994C17.0609 3.99994 18.0783 4.42137 18.8284 5.17151C19.5786 5.92166 20 6.93907 20 7.99994H12C12 6.93907 12.4214 5.92166 13.1716 5.17151C13.9217 4.42137 14.9391 3.99994 16 3.99994ZM25 26.9999H7V5.99994H10.3438C10.1163 6.64221 10 7.31858 10 7.99994V8.99994C10 9.26516 10.1054 9.51951 10.2929 9.70705C10.4804 9.89458 10.7348 9.99994 11 9.99994H21C21.2652 9.99994 21.5196 9.89458 21.7071 9.70705C21.8946 9.51951 22 9.26516 22 8.99994V7.99994C22 7.31858 21.8837 6.64221 21.6562 5.99994H25V26.9999Z" />
                  </svg>
                </SvgIcon>
              </IconButton>
            </Tooltip>

            <IconButton
              color={vote?.vote === "yes" ? "primary" : undefined}
              onClick={() => {
                setShowDownvoteJustifier(false);
                if (vote?.vote === "yes") {
                  onVote();
                  return;
                }

                onVote({
                  vote: "yes",
                });
              }}
            >
              <SvgIcon
                sx={{
                  height: 20,
                  width: 20,
                }}
                inheritViewBox={true}
                fill={"currentColor"}
              >
                {/* Up */}
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M29.25 10.015C28.9684 9.69591 28.6222 9.44037 28.2342 9.26537C27.8463 9.09037 27.4256 8.99991 27 9H20V7C20 5.67392 19.4732 4.40215 18.5355 3.46447C17.5979 2.52678 16.3261 2 15 2C14.8142 1.99987 14.6321 2.05149 14.474 2.14908C14.3159 2.24667 14.1881 2.38636 14.105 2.5525L9.3825 12H4C3.46957 12 2.96086 12.2107 2.58579 12.5858C2.21071 12.9609 2 13.4696 2 14V25C2 25.5304 2.21071 26.0391 2.58579 26.4142C2.96086 26.7893 3.46957 27 4 27H25.5C26.2309 27.0003 26.9367 26.7337 27.485 26.2503C28.0332 25.767 28.3861 25.1001 28.4775 24.375L29.9775 12.375C30.0307 11.9525 29.9933 11.5236 29.8679 11.1167C29.7424 10.7098 29.5318 10.3342 29.25 10.015ZM4 14H9V25H4V14ZM27.9925 12.125L26.4925 24.125C26.462 24.3667 26.3444 24.589 26.1617 24.7501C25.9789 24.9112 25.7436 25.0001 25.5 25H11V13.2362L15.5887 4.0575C16.2689 4.19362 16.8808 4.5612 17.3204 5.09768C17.76 5.63416 18.0002 6.3064 18 7V10C18 10.2652 18.1054 10.5196 18.2929 10.7071C18.4804 10.8946 18.7348 11 19 11H27C27.1419 11 27.2822 11.0301 27.4115 11.0885C27.5409 11.1468 27.6563 11.232 27.7502 11.3384C27.8441 11.4448 27.9143 11.57 27.956 11.7056C27.9978 11.8413 28.0102 11.9842 27.9925 12.125Z" />
                </svg>
              </SvgIcon>
            </IconButton>
            <IconButton
              color={vote?.vote === "no" ? "primary" : undefined}
              onClick={() => {
                setShowDownvoteJustifier(false);
                if (vote?.vote === "no") {
                  onVote();
                  return;
                }

                onVote({
                  vote: "no",
                });
                setShowDownvoteJustifier(true);
              }}
            >
              <SvgIcon
                sx={{
                  height: 20,
                  width: 20,
                }}
                inheritViewBox={true}
                fill={"currentColor"}
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M29.9775 19.625L28.4775 7.625C28.3861 6.89985 28.0332 6.233 27.485 5.74966C26.9367 5.26632 26.2309 4.99975 25.5 5H4C3.46957 5 2.96086 5.21071 2.58579 5.58579C2.21071 5.96086 2 6.46957 2 7V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H9.3825L14.105 29.4475C14.1881 29.6136 14.3159 29.7533 14.474 29.8509C14.6321 29.9485 14.8142 30.0001 15 30C16.3261 30 17.5979 29.4732 18.5355 28.5355C19.4732 27.5979 20 26.3261 20 25V23H27C27.4257 23.0001 27.8466 22.9097 28.2346 22.7346C28.6227 22.5596 28.9691 22.3039 29.2507 21.9847C29.5323 21.6655 29.7428 21.2899 29.8681 20.8831C29.9934 20.4762 30.0307 20.0474 29.9775 19.625ZM9 18H4V7H9V18ZM27.75 20.6612C27.6568 20.7685 27.5415 20.8542 27.4121 20.9127C27.2826 20.9712 27.142 21.001 27 21H19C18.7348 21 18.4804 21.1054 18.2929 21.2929C18.1054 21.4804 18 21.7348 18 22V25C18.0002 25.6936 17.76 26.3658 17.3204 26.9023C16.8808 27.4388 16.2689 27.8064 15.5887 27.9425L11 18.7638V7H25.5C25.7436 6.99992 25.9789 7.08877 26.1617 7.24989C26.3444 7.411 26.462 7.63328 26.4925 7.875L27.9925 19.875C28.0112 20.0158 27.9993 20.159 27.9574 20.2947C27.9155 20.4304 27.8448 20.5555 27.75 20.6612Z" />
                </svg>
              </SvgIcon>
            </IconButton>
          </Box>
        </Fade>
        <Collapse in={showDownvoteJustifier}>
          <Box
            sx={{
              width: "fit-content",
              border: (theme) =>
                `1px solid color-mix(in srgb, ${theme.palette.secondary.main}, 50% transparent)`,
              borderRadius: 2,
              p: 2,
              position: "relative",
            }}
          >
            <Typography sx={{ mb: 2 }}>Please share more details:</Typography>
            <IconButton
              sx={{ position: "absolute", top: 2, right: 2 }}
              onClick={() => setShowDownvoteJustifier(false)}
            >
              <Close />
            </IconButton>
            <Box display={"flex"} gap={2} flexWrap={"wrap"}>
              {[
                "Answer doesn't match sources",
                "Off topic",
                "Don't trust answer",
              ].map((reason) => (
                <Button
                  key={reason}
                  variant={"outlined"}
                  color={"secondary"}
                  sx={{ flexGrow: 1 }}
                  onClick={() => {
                    onVote({
                      vote: "no",
                      reason,
                    });
                    setShowDownvoteJustifier(false);
                  }}
                >
                  {reason}
                </Button>
              ))}
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
}

function MD(props: {
  children: string;
  onAnchorClick: MouseEventHandler<HTMLAnchorElement>;
}) {
  return useMemo(() => {
    try {
      return compiler(props.children, {
        overrides: {
          a: {
            props: {
              target: "_blank",
              onClick: props.onAnchorClick,
            },
          },
          p: {
            component: Typography,
            props: {
              sx: { mb: 1 },
            },
          },
        },
      });
    } catch (e) {
      return cleanSlackMrkdwn(props.children);
    }
  }, [props.children, props.onAnchorClick]);
}

function cleanSlackMrkdwn(text: string): React.ReactNode {
  return text.replace(/<([^>]+)\|([^>]+)>/g, (match, url, label) => label);
}

function tryFormat(timestamp?: number) {
  if (!timestamp) return "";
  try {
    let ts = timestamp.toString();
    let n = +ts;
    if (ts.toString().includes(".")) {
      n = +ts.split(".")[0] * 1000;
    }
    if (ts.length === 10) {
      n = +ts * 1000;
    }
    return format(n, "PP");
  } catch (e) {
    return "";
  }
}

const Ol = styled("ol")(({ theme }) => ({
  margin: theme.spacing(0),
  paddingInlineStart: theme.spacing(5),
}));
const Li = styled("li")(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(0),
}));
