import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { BotChat } from "./botChat/BotChat";
import { NotFoundPage } from "./NotFound";
import "./index.css";

const path_env =
  process.env.NODE_ENV === "development" ? "local" : process.env.NODE_ENV;
require("dotenv").config({ path: `.env.${path_env}` });

process.env.NODE_ENV !== "development" &&
  Sentry.init({
    dsn: "https://4c8ce6ccd6f93edea5a5c94430a0a0ab@o1180906.ingest.us.sentry.io/4507314027888640",
    integrations: [new Sentry.BrowserTracing()],
    environment: process.env.NODE_ENV,
    release: process.env.SENTRY_RELEASE,
    tracesSampleRate: 1.0,
  } as Sentry.BrowserOptions);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFoundPage />,
    children: [
      {
        index: true,
        loader() {
          throw new Error("Not found");
        },
      },
      {
        path: "/t/:tenantId/b/:botId",
        element: <BotChat />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
