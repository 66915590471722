const integrationKinds = [
  "clickup",
  "confluence",
  "github",
  "google_drive",
  "google_drive_full_access",
  "jira",
  "onedrive",
  "ms_teams",
  "notion",
  "sharepoint",
  "servicenow",
  "slack",
  "stackoverflowteams",
  "uploaded-docstore",
  "website-docstore",
] as const;
export type IntegrationKind = typeof integrationKinds[number];
export const integrationDisplayNames: Record<
  IntegrationKind,
  { displayName: string }
> = {
  clickup: {
    displayName: "ClickUp",
  },
  confluence: {
    displayName: "Confluence",
  },
  github: {
    displayName: "GitHub",
  },
  google_drive_full_access: {
    displayName: "Google Drive",
  },
  google_drive: {
    displayName: "Google Drive (Individual Files)",
  },
  jira: {
    displayName: "Jira",
  },
  onedrive: {
    displayName: "OneDrive",
  },
  ms_teams: {
    displayName: "Microsoft Teams",
  },
  notion: {
    displayName: "Notion",
  },
  sharepoint: {
    displayName: "SharePoint",
  },
  servicenow: {
    displayName: "ServiceNow",
  },
  slack: {
    displayName: "Slack",
  },
  stackoverflowteams: {
    displayName: "StackOverflow For Teams",
  },
  "uploaded-docstore": {
    displayName: "Uploaded Files",
  },
  "website-docstore": {
    displayName: "Websites",
  },
};

export function getIntegrationDisplayName(
  integrationType: IntegrationKind
): string {
  return (
    integrationDisplayNames[integrationType]?.displayName || integrationType
  );
}
