import React, { FC } from "react";
import { Bot } from "./botChat/types";

export const BotAvatarSlugs = [
  "default",
  "chart",
  "code",
  "document",
  "glasses",
  "people",
  "robot",
] as const;
export type BotAvatarSlug = typeof BotAvatarSlugs[number];

export const BotAvatar: FC<{ bot: Bot; size?: number }> = ({
  bot,
  size = 50,
}) => {
  return (
    <img
      src={bot.bot_avatar_url}
      alt={`Bot Avatar`}
      style={{ width: size, height: size }}
    />
  );
};
