import { createTheme } from "@mui/material/styles";
import { Property } from "csstype";
import convert from "color-convert";

const querypalThemeColors = {
  // primaryBackgroundColor: "#EAEFF5",
  // primaryNavColor: "#E2E8EF",
  // dropdownColor: "#E2E8EF",
  // selectedMenuColor: "#00A3FF50",
  primary: "#00A3FF",
  primaryButtonFontColor: "#ffffff",
  // secondaryButtonColor: "#8189A0",
  tertiary: "#846DFF",
  // warningColor: "#ffa726",
  borderColor: "#9EB0C6",
  textColorOnWhite: "#8189A0",
  // secondaryTextColor: "#61687E",
  headingTextColor: "#656C80",
  fontColor: "#656C80",
  fontColor70pOpacity: "rgb(122,127,142)",
  // tertiaryTextColor: "#3D454A",
  secondaryBackgroundColor: calcBackgroundColor("#00A3FF"),
};

function calcBackgroundColor(hex: string) {
  let s = convert.hex.hsl.raw(hex);
  s[1] = 25;
  s[2] = 97;
  return `#` + convert.hsl.hex(s);
}

interface ThemeColors {
  primary: string;
  tertiary: string;
  fontColor: string;
  headingTextColor: string;
  fontColor70pOpacity: string;
  textColorOnWhite: string;
  borderColor: string;
  secondaryBackgroundColor: string;
}

declare module "@mui/material/styles" {
  interface Theme {
    customColors: ThemeColors;
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    customColors?: Partial<ThemeColors>;
  }
}

export function createCustomTheme(themeColors: Partial<ThemeColors>) {
  const headingFontFamily: Property.FontFamily = "Poppins, Arial, sans-serif";
  const defaultFontFamily: Property.FontFamily = "Lato, sans-serif";

  let c: ThemeColors = {
    ...querypalThemeColors,
    ...themeColors,
  };

  // c.secondaryBackgroundColor = `color-mix(in srgb, gray 50%, ${c.primary})`;
  // c.secondaryBackgroundColor = `color(from ${c.primary} xyz calc(x + 0.75) calc(y - 0) calc(z - 0.7))`;
  let s = convert.hex.hsl.raw(c.primary);
  s[1] = 25;
  s[2] = 97;
  c.secondaryBackgroundColor = `#` + convert.hsl.hex(s);

  return createTheme({
    customColors: c,
    typography: {
      allVariants: {
        color: c.fontColor,
        fontFamily: defaultFontFamily,
      },
      h1: {
        fontSize: "30px",
        fontWeight: 600,
        fontFamily: headingFontFamily,
        color: c.headingTextColor,
      },
      h2: {
        fontSize: "24px",
        fontWeight: 600,

        fontFamily: headingFontFamily,
        color: c.headingTextColor,
      },
      h3: {
        fontFamily: headingFontFamily,
        fontSize: "20px",
        color: c.headingTextColor,
      },
      h4: {
        fontFamily: headingFontFamily,
        color: c.headingTextColor,
      },
      h5: {
        fontFamily: headingFontFamily,
        color: c.headingTextColor,
      },
      h6: {
        fontFamily: headingFontFamily,
        color: c.headingTextColor,
      },
      body1: {
        fontSize: "16px",
      },
      subtitle1: {
        fontSize: "16px",
        color: "#10131C",
      },
      subtitle2: {
        fontSize: "18px",
        color: c.fontColor,
        fontFamily: "Poppins",
        fontWeight: "400",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: (theme) => `
          html,
          body {
            font-family: ${defaultFontFamily};
          }

          a {
            color: ${theme.customColors.primary};
          }

          a:visited {
            color: ${theme.customColors.tertiary};
          }

          // *::-webkit-scrollbar,
          // *::-webkit-scrollbar-track {
          //   width: 8px;
          //   height: 8px;
          //   background: transparent;
          //   border: 0;
          // }
          //
          // *::-webkit-scrollbar-thumb {
          //   background: color-mix(in srgb, ${theme.customColors.primary}, 30% transparent);
          //   border-radius: 8px;
          //   border: 0;
          // }
          //
          // *::-webkit-scrollbar-corner {
          //   background-color: rgba(0, 0, 0, 0);
          // }
        `,
      },
      MuiAlert: {
        styleOverrides: {
          message: {
            padding: "0px",
            display: "flex",
            alignItems: "center",
            fontFamily: "Poppins",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
            boxShadow: "none",
            boxSizing: "unset",
            textTransform: "none",
            fontFamily: defaultFontFamily,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: "#FFFFFF",
            color: "#afafaf",
            padding: "16px",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: "16px",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "14px",
          },
        },
      },
    },
    palette: {
      mode: "light",
      primary: {
        main: c.primary,
        // contrastText: c.primaryButtonFontColor,
      },
      secondary: {
        main: c.fontColor70pOpacity,
      },
      text: {
        primary: c.fontColor,
        secondary: c.textColorOnWhite,
        disabled: `${c.fontColor}50`,
      },
    },
  });
}

export const defaultTheme = createCustomTheme(querypalThemeColors);
