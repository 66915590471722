import { Container, Typography } from "@mui/material";
import React from "react";
import { MainLayout } from "./MainLayout";

export function NotFoundPage() {
  return (
    <MainLayout>
      <NotFoundContent />
    </MainLayout>
  );
}

function NotFoundContent() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        height: "100%",
        "& > *": {
          marginLeft: "auto",
          marginRight: "auto",
        },
      }}
    >
      <Typography sx={{ textAlign: "center", my: "auto" }}>
        404 Not Found
      </Typography>
    </Container>
  );
}
