import { UserMessage } from "./types";
import { Avatar, Box, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import React from "react";

export function UserMessageComponent({
  message,
  id,
}: {
  message: UserMessage;
  id?: string;
}) {
  return (
    <Box
      id={id}
      sx={{
        display: "flex",
        gap: 2,
        backgroundColor: (theme) => theme.customColors.secondaryBackgroundColor,
        p: 2,
        borderRadius: 10,
      }}
    >
      <Avatar variant={"circular"}>
        <PersonIcon />
      </Avatar>
      <Typography color={"black"} sx={{ mt: 1 }}>
        {message.query}
      </Typography>
    </Box>
  );
}
