import { Outlet, useNavigate } from "react-router-dom";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { defaultTheme } from "../theme";
import * as Sentry from "@sentry/react";
import { Box, Button, CssBaseline, Typography } from "@mui/material";
import { useSegmentAnalytics } from "./useSegmentAnalytics";

export function App() {
  useSegmentAnalytics();

  const navigate = useNavigate();
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline enableColorScheme>
        <Sentry.ErrorBoundary
          fallback={({ resetError }) => (
            <Box>
              <Typography variant={"h6"}>
                Whoops! Something went wrong.
              </Typography>
              <Button
                variant={"text"}
                onClick={() => {
                  navigate("/");
                  resetError();
                }}
              >
                Back to Home
              </Button>
            </Box>
          )}
        >
          <Outlet />
        </Sentry.ErrorBoundary>
      </CssBaseline>
    </ThemeProvider>
  );
}
