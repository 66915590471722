import React, { CSSProperties } from "react";
import { Box, SxProps } from "@mui/material";

interface IconProps {
  icon: string;
  sx?: SxProps;
  ext?: string;
}

const Icon: React.FC<IconProps> = ({ icon, sx, ext = "svg", ...props }) => {
  const isHostedAtSubdomain = window.location.href.includes("index.html");
  const src = isHostedAtSubdomain
    ? `/k8s/${ext}_icons/${icon}.${ext}`
    : `/${ext}_icons/${icon}.${ext}`;

  return (
    <Box
      component={"img"}
      alt={""}
      sx={sx}
      src={src}
      {...props}
      // onError={(e) => (e.currentTarget.src = "/svg_icons/_missing_icon.svg")}
    />
  );
};

export default Icon;
