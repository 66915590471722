import type { SegmentAnalytics } from "@segment/analytics.js-core";
import { useEffect } from "react";

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    promptOpsUser: string;
  }
}

// @ts-ignore
export function useSegmentAnalytics() {
  useEffect(() => {
    const segmentWriteKey = process.env.SEGMENT_KEY;
    if (!segmentWriteKey) {
      console.info("No segment key present");
      return;
    }

    var analytics = (window.analytics = window.analytics || []);

    if (!analytics.initialize) {
      // @ts-ignore
      if (analytics.invoked) {
        window.console &&
          console.error &&
          console.error("Segment snippet included twice.");
      } else {
        const hostname = new URL(window.location.href).hostname;
        console.debug(
          "Initializing Segment Analytics for Hostname, WriteKey:",
          hostname,
          segmentWriteKey
        );
        // @ts-ignore
        analytics.invoked = !0;
        // @ts-ignore
        analytics.methods = [
          "trackSubmit",
          "trackClick",
          "trackLink",
          "trackForm",
          "pageView",
          "identify",
          "reset",
          "group",
          "track",
          "ready",
          "alias",
          "debug",
          "page",
          "once",
          "off",
          "on",
          "addSourceMiddleware",
          "addIntegrationMiddleware",
          "setAnonymousId",
          "addDestinationMiddleware",
        ];
        // @ts-ignore
        analytics.factory = function (e) {
          return function () {
            var t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            // @ts-ignore
            analytics.push(t);
            return analytics;
          };
        };

        // @ts-ignore
        for (var e = 0; e < analytics.methods.length; e++) {
          // @ts-ignore
          var key = analytics.methods[e];
          // @ts-ignore
          analytics[key] = analytics.factory(key);
        }
        // @ts-ignore
        analytics.load = function (key, e) {
          var t = document.createElement("script");
          t.type = "text/javascript";
          t.async = true;
          t.src =
            "https://cdn.segment.com/analytics.js/v1/" +
            key +
            "/analytics.min.js";
          var n = document.getElementsByTagName("script")[0];
          // @ts-ignore
          n.parentNode.insertBefore(t, n);
          // @ts-ignore
          analytics._loadOptions = e;
        };
        // @ts-ignore
        analytics._writeKey = segmentWriteKey;
        // @ts-ignore
        analytics.SNIPPET_VERSION = "4.13.2";

        if (segmentWriteKey) {
          // @ts-ignore
          analytics.load(segmentWriteKey, { obfuscate: true });
          analytics.page();
        }
      }
    }
  }, []);
}
