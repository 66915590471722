import { Button } from "@mui/material";
import React from "react";

interface SuggestedPromptProps {
  prompt: string;
  onClick: () => void;
}

export function SuggestedPrompt({ prompt, onClick }: SuggestedPromptProps) {
  return (
    <Button
      onClick={onClick}
      color={"secondary"}
      sx={{
        boxSizing: "inherit",
        maxWidth: 300,
        border: "1px solid",
        borderRadius: 2,
        p: 1,
        display: "block",
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: 1.5,
        borderColor: (theme) => theme.customColors.borderColor,
        fontWeight: "normal",
        "&::first-line": {
          // fontWeight: "bold",
          // fontSize: "14px",
        },
      }}
    >
      {prompt}
    </Button>
  );
}
